const price = [
    {
        name: 56,
        unit: "80000000000000000"
    },
    {
        name: 97,
        unit: "50000000000000000"
    },
    {
        name: 1,
        unit: "20000000000000000"
    },
    {
        name: 1116,
        unit: "10000000000000000000"
    },
    {
        name: 128,
        unit: "2500000000000000000"
    },
    {
        name: 42161,
        unit: "20000000000000000"
    },
    {
        name: 369,
        unit: "100000000000000000000000"
    },{
        name: 20201022,
        unit: "10000000000000000000"
    },{
        name: 7899,
        unit: "100000000000000000000"
    },{
        name: 66,
        unit: "1000000000000000000"
    },{
        name: 59144,
        unit: "10000000000000000"
    },
    {
        name: 8453,
        unit: "20000000000000000"
    }
    ,
    {
        name: 5000,
        unit: "50000000000000000000"
    },{
        name: 719,
        unit: "30000000000000000000"
    },{
        name: 204,
        unit: "100000000000000000"
    },{
        name: 109,
        unit: "30000000000000000000"
    },{
        name: 81457,
        unit: "30000000000000000"
    },{
        name: 4200,
        unit: "300000000000000"
    },{
        name: 137,
        unit: "50000000000000000000"
    },{
        name: 1319,
        unit: "5000000000000000000000"
    },
    {
        name: 534352,
        unit: "20000000000000000"
    },{
        name: 480,
        unit: "20000000000000000"
    },{
        name: 33139,
        unit: "10000000000000000000"
    },

]

export {
    price
}
